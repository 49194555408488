import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getSaldoCarteraByGestorAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getGestoresAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const SaldosCarteraByGestor = () => {
	const [data, setData] = useState([]);

	const compRef = useRef();
	const { selectedGestor } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const token = getToken();
	const user = getUser();

	useEffect(() => {
		(async () => {
			let gestores = await getGestores();

			if (selectedGestor === 't') {
				setGestorName('Todos');
			} else {
				let name = gestores.filter((item) => item.id.toString() === selectedGestor);

				setGestorName(name[0].fullName);
			}

			let gestorId = selectedGestor === 't' ? 0 : selectedGestor;

			setIsLoading(true);
			const result = await getSaldoCarteraByGestorAsync(token, gestorId);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setData(result.data.result);
			setIsLoading(false);
		})();
	}, []);

	const pageStyle = `
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    @media all {
      .pagebreak {
        display: none;
      }
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }  
    @page { size: landscape;  margin: 3mm; }
  `;

	let totalClientsConsol = 0;
	let totalCreditsConsol = 0;

	let totalAlDiaConsol = 0;
	let totalMontoAlDiaConsol = 0;

	let totalMoraConsol = 0;
	let totalMontoMoraConsol = 0;

	let totalVigenteConsol = 0;
	let totalMontoVigenteConsol = 0;

	let totalvencidoConsol = 0;
	let totalMontovencidoConsol = 0;

	let creditoPromConsol = 0;
	let totalSaldoConsol = 0;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			const {
				creditoPromedio,
				dia,
				gestor,
				montoDia,
				montoMora,
				montoVencido,
				montoVigente,
				mora,
				noClients,
				noCredits,
				saldoCartera,
				vencido,
				vigente
			} = item;

			let obj = {
				gestor,
				noClients,
				noCredits,
				dia,
				montoDia,
				percentDia: ((dia / noCredits) * 100).toFixed(2),
				mora,
				montoMora,
				percentMora: ((mora / noCredits) * 100).toFixed(2),
				montoMora,
				vigente,
				montoVigente,
				percentVigente: ((vigente / noCredits) * 100).toFixed(2),
				vencido,
				montoVencido,
				percentVencido: ((vencido / noCredits) * 100).toFixed(2),
				creditoPromedio,
				saldoCartera
			};
			exp.push(obj);
		});

		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'saldosCarteraByGestor');
		XLSX.writeFile(bk, 'SaldosCarteraByGestor.xlsx');
	};

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);

		setGestorList(
			selectedGestor === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedGestor)
		);

		return result.data.result;
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Saldos Cartera Por Gestor
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Gestor
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												No Clientes
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												No Creditos
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Al Dia
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												M. al Dia
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												% al Dia
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Mora
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												M. Mora
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												% Mora
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Vigente
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												M. Vigente
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												% Vigente
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Vencido
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												M. Vencido
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												% Vencido
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Credito Prom.
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Saldo Cartera
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{data.map((row) => {
											const {
												creditoPromedio,
												dia,
												gestor,
												montoDia,
												montoMora,
												montoVencido,
												montoVigente,
												mora,
												noClients,
												noCredits,
												saldoCartera,
												vencido,
												vigente
											} = row;

											totalClientsConsol += noClients;
											totalCreditsConsol += noCredits;

											totalAlDiaConsol += dia;
											totalMontoAlDiaConsol += montoDia;

											totalMoraConsol += mora;
											totalMontoMoraConsol += montoMora;

											totalVigenteConsol += vigente;
											totalMontoVigenteConsol += montoVigente;

											totalvencidoConsol += vencido;
											totalMontovencidoConsol += montoVencido;

											let prom = 0;
											prom += creditoPromedio;
											creditoPromConsol = prom / data.length;

											totalSaldoConsol += saldoCartera;

											return (
												<TableRow key={gestor}>
													<TableCell
														align="left"
														component="th"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{gestor}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{noClients}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{noCredits}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{dia}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(montoDia)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{((dia / noCredits) * 100).toFixed(2)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{mora}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(montoMora)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{((mora / noCredits) * 100).toFixed(2)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{vigente}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(montoVigente)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{((vigente / noCredits) * 100).toFixed(2)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{vencido}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(montoVencido)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{((vencido / noCredits) * 100).toFixed(2)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(creditoPromedio)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoCartera)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							
							{gestorList.length === 1 ? (
								<></>
							) : (
								<Stack>
									<Typography
										sx={{
											color: '#2196f3',
											textAlign: 'center'
										}}
									>
										Consolidado
									</Typography>

									<hr />

									<Stack direction="row" flex="row" justifyContent="space-around">
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Clientes
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalClientsConsol}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Creditos
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalCreditsConsol}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Creditos Al Dia
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalAlDiaConsol}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Creditos al Dia
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalMontoAlDiaConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Creditos Mora
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalMoraConsol}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Creditos en Mora
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalMontoMoraConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Creditos Vigentes
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalVigenteConsol}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Creditos Vigentes
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalMontoVigenteConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Creditos Vencidos
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalvencidoConsol}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Creditos Vencidos
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalvencidoConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Creditos Promedio
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(creditoPromConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Monto Saldo Cartera
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalSaldoConsol)}
											</Typography>
										</Stack>
									</Stack>
									<hr />
								</Stack>
							)}
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Saldos Cartera Por Gestor'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<hr />

						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													Gestor
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													#Clientes
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													#Creditos
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													Dia
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													M.Dia
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													%Dia
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													Mora
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													M.Mora
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													%Mora
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													Vigente
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													M.Vigente
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													%Vigente
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													Vencido
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													M.Vencido
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													%Vencido
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													C.Prom.
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 9 }}
												>
													S.Cartera
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{data.map((row) => {
												const {
													creditoPromedio,
													dia,
													gestor,
													montoDia,
													montoMora,
													montoVencido,
													montoVigente,
													mora,
													noClients,
													noCredits,
													saldoCartera,
													vencido,
													vigente
												} = row;

												return (
													<TableRow key={gestor}>
														<TableCell
															align="left"
															component="th"
															scope="row"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{gestor}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{noClients}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{noCredits}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{dia}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(montoDia)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{((dia / noCredits) * 100).toFixed(2)}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{mora}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(montoMora)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{((mora / noCredits) * 100).toFixed(2)}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{vigente}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(montoVigente)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{((vigente / noCredits) * 100).toFixed(
																2
															)}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{vencido}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(montoVencido)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{((vencido / noCredits) * 100).toFixed(
																2
															)}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(creditoPromedio)}
														</TableCell>

														<TableCell
															align="center"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(saldoCartera)}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						)}

						{gestorList.length === 1 ? (
							<></>
						) : (
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'center'
									}}
								>
									Consolidado
								</Typography>

								<hr />

								<Stack direction="row" flex="row" justifyContent="space-around">
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T.Clientes
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalClientsConsol}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T.Creditos
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalCreditsConsol}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T. Dia
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalAlDiaConsol}
										</Typography>
									</Stack>
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Dia
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalMontoAlDiaConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T.Mora
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalMoraConsol}
										</Typography>
									</Stack>
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Mora
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalMontoMoraConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T.Vigentes
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalVigenteConsol}
										</Typography>
									</Stack>
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Vigentes
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalMontoVigenteConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											T.Vencidos
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{totalvencidoConsol}
										</Typography>
									</Stack>
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Vencidos
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalvencidoConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Promedio
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(creditoPromConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 9
											}}
										>
											M.Saldo Cartera
										</Typography>
										<Typography style={{ fontSize: 8 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalSaldoConsol)}
										</Typography>
									</Stack>
								</Stack>
								<hr />
							</Stack>
						)}
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default SaldosCarteraByGestor;
