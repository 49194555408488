import React, { useContext } from "react";
import {
  faServer,
  faShieldHalved,
  faUsersCog,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";
import { getRuta, isAccess } from "../../helpers/Helpers";
import { DataContext } from "../../context/DataContext";

const Security = ({ drawerIsOpen }) => {
  const [open, setOpen] = React.useState(false);
  const { access } = useContext(DataContext);
  let rout = getRuta();
  return isAccess(access, "SECURITY VER") ? (
    <div>
      <ListItemButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faShieldHalved}
            style={{
              fontSize: 25,
            }}
          />
        </ListItemIcon>
        <ListItemText primary="Seguridad" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Stack marginTop={1} paddingLeft={drawerIsOpen ? 4 : 0.3} spacing={1}>
          <NavLink
            to={`${rout}/users`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faUsersCog}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Usuarios</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/rols`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faUserShield}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Roles</Typography>
            </Stack>
          </NavLink>

          {/* <NavLink
            to={`${rout}/server`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faServer}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Servidor</Typography>
            </Stack>
          </NavLink> */}
        </Stack>
      </Collapse>
    </div>
  ) : (
    <></>
  );
};

export default Security;
