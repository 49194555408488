import React, { useContext, useEffect, useState } from 'react';

import { Typography, Stack } from '@mui/material';

import { DataContext } from '../../../../context/DataContext';
import { conv2Letras } from '../../../../helpers/n2Letras';

import { getToken } from '../../../../api/Account';
import { simpleMessage, num_Letras } from '../../../../helpers/Helpers';
import { getClientFullAddressAsync } from '../../../../api/Clients';

import { isEmpty } from 'lodash';

import '../../../../components/estilos/estilos.css';
import moment from 'moment';
import { num_to_Letra } from '../../../../helpers/num_Letras';
import { getCreditGarantiasAsync } from '../../../../api/Credits';
import { getTipoCambioDiaAsync } from '../../../../api/TipoCambio';
// import { conv2LetrasDolar } from '../../../../helpers/n2LetrasDolar';

export const PrintPagare = React.forwardRef((props, ref) => {
	const [plazoMes, setPlazoMes] = useState(0);
	const [address, setAddress] = useState('');

	const [mes, setMes] = useState('');
	const [garantiaList, setGarantiaList] = useState([]);
	const [tipoCambio, setTipoCambio] = useState(0);

	const {
		title,
		slogan,
		icon,
		setIsLoading,
		setIsUnautorized,

		representanteLegal
	} = useContext(DataContext);
	const {
		id,
		client,
		montoPagar,
		montoEntregado,
		plazo,
		periocidad,
		cuotaPagar,
		fechaEntrega,
		fechaVencimiento,
		fechaPrimeraCuota,
		fechaCreacion,

		interesCorriente
	} = props.dataCredit;

	const getPageMargins = () => {
		return `body {
          color:black;
        }`;
	};

	const token = getToken();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getClientFullAddressAsync(token, client.id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setAddress(result.data.result);

			await getGarantias();
			await getTipoCambio();

			setIsLoading(false);
		})();
		setPlazoMes((periocidad.cuotaDias * plazo.cuotas) / periocidad.cantidadDias);
		let fecha = new Date(fechaEntrega);

		const monthNameLong = fecha.toLocaleString('es-NI', { month: 'long' });
		setMes(monthNameLong);
	}, []);

	const convertCedula = (cedula) => {
		return num_Letras(cedula);
	};

	const getGarantias = async () => {
		const result = await getCreditGarantiasAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setGarantiaList(result.data.result);
	};

	const getTipoCambio = async () => {
		const result = await getTipoCambioDiaAsync(
			token,
			moment(fechaEntrega).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setTipoCambio(result.data);
	};

	const getValorGarantias = () => {
		let valor = 0;

		garantiaList.map((item) => (valor += item.valorEstimado));
		return valor;
	};

	return isEmpty(props.dataCredit) ? (
		<></>
	) : (
		<div ref={ref}>
			<style>{getPageMargins()}</style>
			<>
				<Stack direction="row" justifyContent="space-between">
					<img src={icon} style={{ width: 70, height: 70 }} />
					<Stack>
						<Typography variant="h5" color="#212121" textAlign={'center'}>
							{title}
						</Typography>
						<Typography color="#212121" textAlign={'center'}>
							{slogan}
						</Typography>
						<h6>CONTRATO DE CREDITO GARANTIZADO CON GARANTIA MOBILIARIA.</h6>
					</Stack>
					<img src={icon} style={{ width: 70, height: 70 }} />
				</Stack>

				<p className="pagareP">
					Nosotros: <strong>{representanteLegal}</strong>, mayor de edad, soltero,
					Comerciante, de este domicilio, propiamente{' '}
					<span style={{ textDecoration: 'underline' }}>
						de donde fue la morgue del Hospital Materno Infantil una cuadra al oeste
						media cuadra al sur,{' '}
					</span>
					identificado con Cédula de Identidad número: cero, ocho, uno, guion, dos, cero,
					cero, siete, siete, cuatro, guion, cero, cero, cero, cinco, B
					(081-200774-0005B), quien actúa en calidad de Gerente Propietario, actuando en
					nombre y representación de <strong>{title}</strong>, con domicilio en el
					municipio de Chinandega, departamento de Chinandega, Republica de Nicaragua,{' '}
					<strong>
						específicamente en la dirección que cita: De donde fue la Morgue del
						Hospital Materno Infantil una cuadra al oeste, media cuadra al Sur
					</strong>{' '}
					demostrando la existencia legal de su representada con los siguientes
					documentos: <strong>a)</strong> Testimonio de Escritura Publica Número
					siete.-(07 ), denominada <strong>inscripción de prestamista</strong>, suscrita
					en la ciudad de Chinandega, Departamento de chinandega a las tres y cuarenta y
					cinco minutos de la tarde del día catorce de marzo del año dos mil veinticuatro,
					ante los oficios notariales de la Licenciada{' '}
					<strong>Jamileth Catalina Meza Sánchez</strong>, inscrito como Prestamista bajo
					la cuenta registral
					<strong>MC-XH944A</strong>, asiento electrónico numero primero . B) Testimonio
					de Inscripción de Comerciante Individual, suscrita en la Ciudad de Chinandega a
					las diez y quince minutos de la mañana del día dieciocho de marzo del año dos
					mil veinticuatro, ante los oficios notariales de la Licenciada Jamileth Catalina
					Meza Sánchez, inscrito bajo la cuenta registral numero:
					<strong>MC-XH944A</strong>,asiento electrónico numero segundo, ambos documentos
					inscritos en el Registro de la propiedad Inmueble y Mercantil y de Garantías
					mobiliarias de este departamento de Chinandega. Aquien en lo sucesivo de este
					contrato se le llamara “El Acreedor” y el señor: y el señor (a):{' '}
					{props.clientName} mayor de edad, {client.civilStatus}, Comerciante, y de este
					domicilio con dirección exacta {address}, identificado con Cédula de Identidad
					Número {convertCedula(client.identification)} ({client.identification}
					),quien en lo sucesivo de esta contrato de crédito se denominara como{' '}
					<strong>Deudor Mobiliario</strong> y en la calidad que intervenimos y teniendo a
					la vista los documentos que nos acreditan y nos identifican para la realización
					de esta acto, reconociéndonos nuestra capacidad jurídica y legal para contratar
					y de común acuerdo reconocemos,{' '}
					<span style={{ textDecoration: 'underline' }}>
						Contrato de Crédito Garantizado con Garantía Mobiliario Otorgado a Una
						Persona.
					</span>{' '}
					Habla el Señor(a) <strong>{props.clientName}</strong> y dice:
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA PRIMERA (RELACION DE CREDITO Y MUTUO)
						</span>
					</strong>{' '}
					Que por medio de este Contrato Privado, acepta haber recibido del señor:{' '}
					<strong>{representanteLegal}</strong>calidad de propietario y Gerente general,
					actuando en nombre y representación de <strong>{title}</strong>, el día de hoy{' '}
					<strong>{`${moment().format('DD')} días del mes de
					${moment().format('MMMM')} del año dos mil
					${moment(fechaCreacion).format('YYYY')}.`}</strong>{' '}
					, un Crédito en calidad de MUTUO por la suma de {conv2Letras(montoEntregado)} (
					{new Intl.NumberFormat('es-NI', {
						style: 'currency',
						currency: 'NIO'
					}).format(montoEntregado)}
					), los cuales confiesa tener recibidos a su entera satisfacción en este acto. El
					deudor Mobiliario hará uso del crédito recibido en calidad de mutuo, para
					invertirlos en capital de trabajo{' '}
					<strong>CLAUSULA SEGUNDA (INTERES CORRIENTE Y MORATORIO)</strong> ): Expresan
					conjuntamente al acreedor y el deudor: Que el crédito tendrá una carga
					financiera del {num_to_Letra(interesCorriente)} por ciento mensual (
					{interesCorriente.toFixed(2)}%) porcentaje que incluye interés corriente.{' '}
					<strong>CLAUSULA TERCERA (PLAZO Y FORMA DE PAGO)</strong> El crédito antes
					referido será pagado en un plazo de {plazoMes} MESES, siendo su fecha de
					vencimiento el {num_to_Letra(moment(fechaVencimiento).format('DD'))} de{' '}
					{moment(fechaVencimiento).format('MMMM')} del{' '}
					{num_to_Letra(moment(fechaVencimiento).format('YYYY'))}, iniciando el pago de la
					primera cuota el {num_to_Letra(moment(fechaPrimeraCuota).format('DD'))} de{' '}
					{moment(fechaPrimeraCuota).format('MMMM')} del{' '}
					{num_to_Letra(moment(fechaPrimeraCuota).format('YYYY'))} , hasta por la suma de{' '}
					{conv2Letras(cuotaPagar)}{' '}
					{new Intl.NumberFormat('es-NI', {
						style: 'currency',
						currency: 'NIO'
					}).format(cuotaPagar)}{' '}
					CADA CUOTA, que incluyen el interés pactado, las que deberán ser depositadas por
					el deudor en las oficinas de <strong>{title}</strong> o en manos del gestor de
					cobro de la institución debidamente identificado. La falta de pago del deudor de
					sus obligaciones en las fechas convenidas en la presente Escritura, lo hará
					incurrir en MORA sin necesidad de requerimiento o cobro alguno judicial o
					extrajudicial, debiendo reconocer los intereses penales o moratorios convenidos,
					pudiendo el acreedor dar por vencido el total de lo adeudado y proceder a su
					inmediato cobro con sólo faltar al pago de una (01) de las cuotas convenidas.-
					Habla el Señor:<strong>{props.clientName}</strong> y dice:
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA CUARTA (CONSTITUCION DE GARANTIA MOBILIARIA):
						</span>
					</strong>{' '}
					)Que para garantizar mejor el pago de la obligación contraída con{' '}
					<strong>{title}</strong>, en este acto constituye GARANTIA MOBILIARIA sobre los
					siguientes bienes muebles electrodomésticos:{' '}
					{garantiaList.length === 0
						? 'NO HAY GARANTIAS'
						: garantiaList.map((item) => {
								return ` un ${item.articulo} de su propiedad con las siguientes características: marca ${item.marca}, modelo ${item.modelo}, serie ${item.serie}, color ${item.color}, `;
						  })}{' '}
					Sigue diciendo el compareciente que: a) Constituye deposito convencional sobre
					la garantía mobiliaria ampliamente descrita y especificada en esta cláusula,
					quedando la misma en poder de su dueño como depositario con obligación de
					custodiarla, cuidarla, y entregarla al acreedor cuando fuere requerido para tal
					fin, pudiendo hacer cesar el presente deposito convencional una vez que cancele
					el crédito, todo conforme lo establecido en el articulo 3449 y siguientes del
					Código Civil Vigente de Nicaragua.- b) El deudor conservará a nombre del
					acreedor la posesión de los objetos pignorados, pudiendo usar de ellos sin
					menoscabo de su valor, estando obligado a realizar por su cuenta los trabajos de
					mantenimiento, conservación y reparación. c) El deudor tendrá respecto a los
					bienes pignorados los deberes y responsabilidades de los depositarios. d) El
					deudor se obliga a informar por escrito al acreedor cualquier desmérito,
					desmejoras, perjuicios, o daños que pudieren ocurrirle a los bienes pignorados,
					debiendo hacerlo dentro de un plazo no mayor de cuarenta y ocho horas de
					ocurrido. d) Los bienes pignorados solo podrán ser sacados del domicilio de
					deudor, que es donde se encuentran, con permiso escrito del acreedor. e) Queda
					prohibido al deudor celebrar otro Contrato de garantía mobiliaria sobre los
					bienes Pignorados. f) El deudor asume lo relativo y legalmente establecido en
					cuanto al Apremio Corporal en materia civil, artos 2521 C y siguientes, por lo
					que hace a la presentación de las garantías mobiliarias.
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA QUINTA: (RECARGOS POR COBRANZA ADMINISTRATIVA Y COBRANZA
							EXTRAJUDICIAL)
						</span>
					</strong>{' '}
					EL deudor en caso de incurrir en mora, después del primer día de atraso,
					reconocerá y pagará al deudor en concepto de cobranza administrativa el diez por
					ciento (10%) calculado sobre la cuota de amortización en mora y los días
					atrasados. Habla “El deudor mobiliario” y dice:
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA SEXTA (VENCIMIENTO ANTICIPADO)
						</span>
					</strong>{' '}
					Que el plazo prefijado para el Préstamo, podrá ser exigible de inmediato por el
					acreedor en los siguientes casos: a) Si se faltara al pago de una(01) sola de
					las cuotas convenidas, en la fecha fijada. b) Si el deudor diere a los fondos un
					uso distinto al convenido en el presente contrato. c) Si el deudor fuere
					ejecutado judicialmente por cualquier persona natural o jurídica durante la
					vigencia del crédito. d) Si sobre el bien hipotecado o garantía mobiliaria
					recayere embargo o ejecución. e) Si el deudor gravare nuevamente el bien dado en
					hipoteca o garantía mobiliaria sin autorización del acreedor. f) Si se
					proporcionare datos o informaciones falsas a <strong>{title}</strong> , el
					fiador o los bienes Mobiliarios.
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA SEPTIMA: (RENUNCIAS
						</span>
					</strong>{' '}
					El deudor Mobiliario y los fiadores solidarios dicen, RENUNCIAN: a) A las
					excepciones provenientes del caso fortuito y fuerza mayor, cuyos riesgos asumen
					en su totalidad. c) A los trámites del juicio de Ejecución Forzosa y se someten
					al que su Acreedor elija y a su vez autoriza al acreedor a subastar o rematar
					los bienes mobiliario o hipotecados, por el valor a que estuviere reducida la
					deuda a dicha fecha. d) Al derecho de ser intimado de pago para los efectos de
					la mora; e) A gozar del plazo del préstamo por la falta de pago del interés y
					cuotas de amortización de lo prestado, en tal caso el acreedor podrá dar por
					vencido el plazo de la obligación y exigir judicialmente el saldo que de la
					misma existiera, con la falta de pago de una sola cuota a las que se ha
					obligado; g) Al derecho de ser depositario de los bienes que se le embarguen; h)
					al derecho de perseguir primero la garantía constituida, pues el acreedor podrá
					perseguir antes, después o simultáneamente con el bien mobiliario, otros bienes
					del deudor, sin perder su derecho preferente; i) Al derecho de ser notificado de
					cualquier cesión y/o subrogación que de este crédito haga el acreedor a
					cualquier persona natural o jurídica. j) Al derecho de nombrar perito valuador
					de bienes inmuebles o muebles gravados. h) A que se valore en bien dado en
					hipoteca o garantías mobiliarias. Hablan en forma conjunta todos los
					comparecientes y dicen:
					<strong>
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							CLAUSULA OCTAVA: (ACEPTACION)
						</span>
					</strong>{' '}
					).- Que ACEPTAN, el contrato de MUTUO GARANTIZADO CON GARANTIA MOBILIARIA,
					OTORGADO A UNA PERSONA.-Y leído que fue a los comparecientes, la encuentran
					conforme, aprueban, ratifican y firman junto conmigo la Notario que doy fe de
					todo lo aquí relacionado. Chinandega, {moment(fechaCreacion).format('DD')} días
					del mes de {moment(fechaCreacion).format('MMMM')} del año dos mil{' '}
					{moment(fechaCreacion).format('YYYY')}.
				</p>

				<Stack
					direction={'row'}
					spacing={2}
					marginTop={5}
					justifyContent="space-around"
					marginBottom={5}
				>
					<Stack spacing={2} justifyContent="center">
						<hr style={{ borderBlockColor: 'black' }} />
						<Stack justifyContent="center">
							<Typography textAlign={'center'} color="#212121">
								<strong>{representanteLegal}</strong>
							</Typography>
							<Typography textAlign={'center'} color="#212121">
								<strong>EL ACREEDOR</strong>
							</Typography>
						</Stack>
					</Stack>

					<Stack spacing={2} justifyContent="center">
						<hr style={{ borderBlockColor: 'black' }} />
						<Stack justifyContent="center">
							<Typography textAlign={'center'} color="#212121">
								<strong>{props.clientName}</strong>
							</Typography>
							<Typography textAlign={'center'} color="#212121">
								<strong>EL DEUDOR (A)</strong>
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				<p className="pagareP">
					<strong>AUTENTICA NOTARIAL: ANTE MI: REYNA MARGARITA LIRA RIVERA</strong>{' '}
					Abogado y Notario Público de la República de Nicaragua, autorizada por la
					Excelentísima Corte Suprema de justicia para el ejercicio del notariado durante
					el quinquenio que finaliza el día Dieciocho de Diciembre del año Dos Mil
					Veintisiete, <strong>, CERTIFICA Y DA FE</strong> Que las firmas que aparecen en
					el documento que antecede fueron puesta en mi presencia por los señores:{' '}
					<strong>{representanteLegal}</strong>, mayor de edad, Casado, Comerciante, de
					este domicilio, propiamente de donde fue la morgue del Hospital Materno Infantil
					una cuadra al oeste media cuadra al sur, identificado con Cédula de Identidad
					número: cero, ocho, uno, guion, dos, cero, cero, siete, siete, cuatro, guion,
					cero, cero, cero, cinco, B (081-200774-0005B),, en calidad de Acreedor y{' '}
					<strong>{props.clientName}</strong> mayor de edad, soltera, Comerciante, de este
					domicilio, e identificada con Cédula de Identidad ciudadana numero:{' '}
					{convertCedula(client.identification)} ({client.identification}), en calidad de
					Deudor.- En fe de lo cual firmo la presente Autentica Notarial en el municipio
					de Chinandega, departamento de Chinandega, a las Tres y veinte minutos de la
					tarde del día {moment(fechaCreacion).format('DD')} días del mes de{' '}
					{moment(fechaCreacion).format('MMMM')} del año dos mil{' '}
					{moment(fechaCreacion).format('YYYY')}.
				</p>

				<Stack direction={'row'} spacing={2} marginTop={5} justifyContent="space-around">
					<Stack spacing={2} justifyContent="center">
						<hr style={{ borderBlockColor: 'black' }} />
						<Stack justifyContent="center">
							<Typography textAlign={'center'} fontWeight={'bold'} color="#212121">
								REYNA MARGARITA LIRA RIVERA
							</Typography>
							<Typography textAlign={'center'} color="#212121">
								Abogado y Notario Público
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</>
		</div>
	);
});

export default PrintPagare;
