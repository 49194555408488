import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCirclePlus,
  faTrashAlt,
  faUserCheck,
  faKey,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataContext";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import {
  deleteUserAsync,
  getAllUserAsync,
  getInactiveUserListAsync,
  getUserListAsync,
  reactivateUserAsync,
  resetUserPasswordAsync,
} from "../../../api/Users";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

const Userlist = () => {
  const [userList, setUserList] = useState([]);

  const [localReload, setLocalReload] = useState(false);
  const { setIsLoading, setIsLogged, setIsUnautorized, access } =
    useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  const [active, setActive] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = userList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.id.toString().includes(searchTerm) ||
      val.fullName.toString().includes(searchTerm) ||
      val.userName.toString().includes(searchTerm.toLocaleLowerCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let result = [];
      if (active === 0) {
        result = await getUserListAsync(token);
      }

      if (active === 1) {
        result = await getInactiveUserListAsync(token);
      }

      if (active === 2) {
        result = await getAllUserAsync(token);
      }

      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setUserList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteUser = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteUserAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Usuario Eliminado.", "success");
        })();
      }
    });
  };

  const reactivateUser = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "Darás acceso al sistema a este usuario...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Proceder!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await reactivateUserAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Reactivado!", "Usuario activado.", "success");
        })();
      }
    });
  };

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const onSelectChange = async (value) => {
    setActive(value);
    setIsLoading(true);
    let result = [];
    if (value === 0) {
      result = await getUserListAsync(token);
    }

    if (value === 1) {
      result = await getInactiveUserListAsync(token);
    }

    if (value === 2) {
      result = await getAllUserAsync(token);
    }

    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setUserList(result.data.result);
  };

  const resetPassword = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "Se estableceras la contraseña por defecto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Proceder!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await resetUserPasswordAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire(
            "Contrseña Reseteada!",
            "Nueva Contraseña es 123456.",
            "success"
          );
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Usuarios
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {isAccess(access, "SECURITY CREATE") ? (
                <Button
                  onClick={() => {
                    navigate(`${ruta}/adduser`);
                  }}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                  variant="outlined"
                  style={{
                    color: "#00a152",
                    borderColor: "#00a152",
                  }}
                >
                  Agregar Usuario
                </Button>
              ) : (
                <></>
              )}

              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Usuarios
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onSelectChange(e.target.value)}
                  value={active}
                  label="Usuarios"
                >
                  <MenuItem value={0}>Activo</MenuItem>
                  <MenuItem value={1}>Inactivo</MenuItem>
                  <MenuItem value={2}>Todos</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar usuario"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        Usuario
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Nombre Completo
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Teléfono
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Rol
                      </TableCell>
                      {isAccess(access, "SECURITY UPDATE") ||
                      isAccess(access, "SECURITY DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="left" component="th" scope="row">
                            {row.userName}
                          </TableCell>
                          <TableCell align="left">{row.fullName}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="left">
                            {row.userRol.rolName}
                          </TableCell>
                          {isAccess(access, "SECURITY UPDATE") ||
                          isAccess(access, "SECURITY DELETE") ? (
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent="center"
                              >
                                {isAccess(access, "SECURITY UPDATE") ? (
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      navigate(`${ruta}/user/${row.id}`);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {isAccess(access, "SECURITY UPDATE") ? (
                                  row.isActive ? (
                                    <IconButton
                                      style={{
                                        marginRight: 5,
                                        color: "#f44336",
                                      }}
                                      onClick={() => {
                                        resetPassword(row);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faKey} />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}

                                {isAccess(access, "SECURITY DELETE") ? (
                                  <IconButton
                                    style={{
                                      color: "#f50057",
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() =>
                                      row.isActive
                                        ? deleteUser(row)
                                        : reactivateUser(row)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        row.isActive ? faTrashAlt : faUserCheck
                                      }
                                    />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default Userlist;
