import React, { useState, useContext, useEffect } from 'react';
import { Container, Stack, AppBar, Toolbar, Typography, Dialog } from '@mui/material';
import { isEmpty } from 'lodash';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { getToken, getUser } from '../../../api/Account';
import NoData from '../../../components/NoData';
import moment from 'moment';
import { simpleMessage } from '../../../helpers/Helpers';
import { getUserTrackingAsync } from '../../../api/Report';
import { getGestoresAsync } from '../../../api/Users';

const ReporteUserTracking = () => {
	const [data, setData] = useState([]);
	const [gestorName, setGestorName] = useState('');

	const [center, ] = useState([12.629763888889, -87.127811111111]);
	// const [recorrido, setRecorrido] = useState([]);

	const { selectedGestor } = useParams();
	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	// const mark = require('../../../components/media/marker.png');
	// const markMoto = require('../../../components/media/motoIcon.png');
	// const markMotoRojo = require('../../../components/media/motoIconRojo.png');
	const markMotoAzul = require('../../../components/media/motoIconAzul.png');

	const token = getToken();
	const user = getUser();

	const getData = async () => {
		setIsLoading(true);
		const result = await getUserTrackingAsync(
			token,
			selectedGestor === 't' ? 0 : selectedGestor
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setData(result.data.result);
		return result.data.result;
	};

	useEffect(() => {
		(async () => {
			let gestores = await getGestores();
			if (selectedGestor === 't') {
				setGestorName('Todos');
			} else {
				let name = gestores.filter((item) => item.id.toString() === selectedGestor);
				setGestorName(name[0].fullName);
			}

			const data = await getData();
		})();
	}, []);

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresAsync(token);

		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		return result.data.result;
	};

	const customIcon = new Icon({
		iconUrl: markMotoAzul,
		iconSize: [50, 50]
	});

	return (
		<Dialog fullScreen open={true}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					<Typography
						sx={{ ml: 2, flex: 1, textAlign: 'center' }}
						variant="h5"
						component="div"
					>
						{`${title} `}
					</Typography>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
				</Toolbar>
			</AppBar>
			<Stack display="flex" justifyContent="center">
				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
				>
					{slogan}
				</Typography>

				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
					variant="h6"
					component="div"
				>
					Reporte de Restreo de usuarios
				</Typography>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parámetros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Gestor Seleccionado: ${gestorName}`}
							</Typography>

							<Stack direction={'row'} spacing={1}>
								<Typography fontSize={11} textAlign="left">
									{/* {`Fecha Seleccionada: ${moment(selectedFecha).format(
										'L'
									)} `} */}
								</Typography>
							</Stack>
						</Stack>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>

							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>
					</Stack>
				</Container>
			</Stack>
			<Container maxWidth={false} sx={{ textAlign: 'center' }}>
				<hr />

				{isEmpty(data) ? (
					<NoData />
				) : (
					<MapContainer
						center={center}
						zoom={16}
						style={{
							height: '100vh'
						}}
					>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{/* <Polyline positions={recorrido} color="blue" /> */}
						{data.map((item) => {
							return (
								<Marker
									key={item.id}
									position={[item.latitude, item.longitude]}
									icon={customIcon}
								>
									<Popup>
										<Stack>
											<Typography
												fontWeight={'bold'}
												textAlign={'center'}
												component={'h6'}
											>
												{item.fullName}
											</Typography>
											<Typography
												fontWeight={'bold'}
												textAlign={'center'}
												component={'span'}
											>
												{moment(item.fecha).format('HH:mm A')}
											</Typography>

											<Typography
												textAlign={'center'}
												component={'span'}
											>{`Precicion: ${item.position.coords.accuracy.toFixed(
												2
											)} metros`}</Typography>
										</Stack>
									</Popup>
								</Marker>
							);
						})}
					</MapContainer>
				)}
			</Container>
		</Dialog>
	);
};

export default ReporteUserTracking;
